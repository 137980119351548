import { merge } from "theme-ui";
import { tailwind } from "@theme-ui/presets"
import originalTheme from "@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui/index";

// https://github.com/LekoArts/gatsby-themes/blob/main/themes/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui/index.js

const theme = merge(originalTheme, {
  initialColorModeName: `dark`, // doesn't work
  colors: {
    background: '#eee',
    primary: tailwind.colors.blue[7],
    modes: {
      dark: {
        background: '#333',
        primary: tailwind.colors.blue[5],
        divide: tailwind.colors.gray[6],
        muted: tailwind.colors.gray[6],
      }
    },
  },
  fonts: {
    body: `'Inter', sans-serif`,
  },
  styles: {
    p: {
      //fontSize: ['1rem'], // doesn't work
      fontSize: [1], // doesn't work
    },
    ul: {
      li: {
        fontSize: [1],
      }
    },
    ol: {
      li: {
        fontSize: [1],
      },
    },
    h1: {
      fontSize: [6],
    },
    h2: {
      fontSize: [5],
    },
    h3: {
      fontSize: [4],
    },
    h4: {
      fontSize: [3],
    },
    h5: {
      fontSize: [2],
    },
    h6: {
      fontSize: 1,
    },
  },
  copyButton: {
    fontSize: [`14px`],
  },
  links: {
    listItem: {
      fontSize: [1, 2],
    },
  },
});

export default theme;